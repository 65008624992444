import React from "react"
import Layout from "../components/layout"

export default () => (

<Layout>
<h1>Repertoire</h1>
<div class="cv-section">
<h2>Solo Viola</h2>
<i>Bach, J.S.</i><ul>
<li>Cello Suites</li>
<li>Violin Partita No. 3 in E.</li></ul>
<i>Biber, H.I.</i><ul>
<li>Passacaglia</li></ul>
<i>Britten, Benjamin</i><ul>
<li>Elegie</li></ul>
<i>Penderecki, Krystof</i><ul>
<li>Cadenza</li></ul>
<i>Reger, Max</i><ul>
<li>3 Suites for viola</li></ul>
<i>Stravinsky</i><ul>
<li>Elegie</li></ul>
<i>Ysaye, E.</i><ul>
<li>Sonata for Violin No. 2 (transcription by Irma Servatius)</li></ul>
</div>
<div class="cv-section">
<h2>Viola Sonatas</h2>
<i>Bach, J.S.</i><ul>
<li>Sonatas for Viola da Gamba</li></ul>
<i>Brahms, J.</i><ul>
<li>Sonata in f. Op. 120 No.1</li>
<li>Sonata in E-flat. Op. 120 No. 2</li></ul>
<i>Hindemith</i><ul>
<li>Meditation for Viola and Piano (1938)</li></ul>
<i>Milhaud</i><ul>
<li>Quatre Visages</li></ul>
<i>Schumann</i><ul>
<li>M�rchenbilder</li></ul>
<i>Schubert</i><ul>
<li>Sonata per Arpeggione</li></ul>
<i>Siegl, Otto</i><ul>
<li>Viola Sonate No. 2</li></ul>
<i>Schostakovich</i><ul>
<li>Viola Sonata</li></ul>
</div>
<div class="cv-section">
<h2>Viola Concertos</h2>
<i>Hindemith</i><ul>
<li>Schwanendreher (1935/6)</li>
<li>Trauermusik (1936)</li></ul>
<i>Mozart</i><ul>
<li>Sinfonie Concertante</li></ul>
<i>Stamitz</i><ul>
<li>Viola Concerto in D.</li></ul>
<i>Telemann</i><ul>
<li>Viola Concerto in G.</li></ul>
</div>
<div class="cv-section"><h2>Chamber Music, Viola et. al</h2>
<i>Barber, Samuel</i><ul>
<li>String Quartet</li></ul>
<i>Beethoven</i><ul>
<li>String Trios</li></ul>
<i>Borodin A.</i><ul>
<li>String Quartet No. 2. in D.</li></ul>
<i>Brahms</i><ul>
<li>Sextet No. 1</li>
<li>Sextet No. 2</li>
</ul>
<i>Dohnanyi</i><ul>
<li>Serenade</li></ul>
<i>Dvorak, Antonin</i><ul>
<li>String Quartet No. 12. in F. "The American"</li>
<li>Terzetto in C.</li></ul>
<i>Fuchs, Robert</i><ul>
<li>12 Duets</li></ul>
<i>Hindemith, Paul</i><ul>
<li>Duet for Viola and Violoncello</li>
<li>2. Trio (1933)</li></ul>
<i>Kienzl, Wilhelm</i><ul>
<li>String Quartet No. 1</li></ul>
<i>LeClair, Jean-Marie</i><ul>
<li>Sonatas for Two Violins (or Violas)</li></ul>
<i>Mendelssohn, F.</i><ul>
<li>Quartet No. 1 in E-flat</li>
<li>Octet</li></ul>
<i>Mozart</i><ul>
<li>String Quartet No. 15. in G. K387</li>
<li>String Quartet No. 15. in d. K421</li>
<li>String Quartet No. 17. in B-flat. "The Hunt" K458</li>
<li>String Quintet No. 4, K516</li>
<li>Duo for Violin and Viola, K423</li>
<li>Duo for Violin and Viola, K424</li></ul>
<i>Piston, Walter</i><ul>
<li>Duet for Viola and Violoncello</li></ul>
<i>Ravel, Maurice</i><ul>
<li>String Quartet</li></ul>
<i>Schubert</i><ul>
<li>String Quartet No. 13 in d. "Death and the Maiden" D810</li>
<li>String Trio</li></ul>
<i>Schostakovich</i><ul>
<li>Quartet No. 1</li>
<li>Quartet No. 2</li>
<li>Quartet No. 8</li></ul>
</div>
</Layout>)
